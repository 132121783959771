import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
interface ItemWithIconProps {
  text: JSX.Element | string;
  icon?: JSX.Element;
}

const ItemWithIcon: React.FC<ItemWithIconProps> = ({ text, icon }) => {
  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemIcon>
        {icon ? (
          icon
        ) : (
          <CircleRoundedIcon
            sx={{
              fontSize: '0.6rem',
              ml: 1
            }}
          />
        )}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default ItemWithIcon;
