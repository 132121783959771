// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Container, Grid, Link, Typography, Stack } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

// assets
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

import LogoInstagram from 'assets/images/landing/footer/logo-instagram.png';
import LogoLinkedIn from 'assets/images/landing/footer/logo-linkedIn.png';

// import logoDark from 'assets/images/logo-white.svg';
const logoDir = require.context('assets/images');

// styles
const FooterWrapper = styled('div')(({ theme }) => ({
  padding: '35px 0',
  color: '#fff',
  background: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    textAlign: 'center'
  }
}));

const FooterLink = styled(Link)({
  color: '#fff',
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'none !important',
  opacity: '0.8',
  '& svg': {
    fontsize: '1.125rem',
    marginRight: 8
  },
  '&:hover': {
    opacity: '1'
  }
});

// ==============================|| LANDING - FOOTER PAGE ||================= //

const FooterPage = () => {
  const theme = useTheme();
  return (
    <>
      <FooterWrapper>
        <Container>
          <Grid
            container
            alignItems="center"
            spacing={gridSpacing}
            direction="row"
          >
            {/* Logo */}
            <Grid item xs={12} sm={2}>
              <Link href="/">
                <img
                  src={logoDir('./logo-white-text.png')}
                  alt="Diingu logo in white"
                  width="130"
                />
              </Link>
            </Grid>
            {/* links */}
            <Grid item xs={12} sm={8}>
              <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Stack direction="row" spacing={4}>
                  <Typography
                    component="div"
                    color="inherit"
                    variant="subtitle1"
                  >
                    &copy; Diingu {new Date().getFullYear()}
                  </Typography>
                  <Link
                    href="#/legal"
                    underline="hover"
                    color="inherit"
                    variant="subtitle1"
                  >
                    Impressum
                  </Link>
                  <Link
                    href="#/privacy"
                    underline="hover"
                    color="inherit"
                    variant="subtitle1"
                  >
                    Datenschutz
                  </Link>
                </Stack>
                <Typography component="div" color="inherit" variant="subtitle1">
                  Illustrations by{' '}
                  <Link
                    href="https://storyset.com/"
                    target="_blank"
                    underline="hover"
                    color="inherit"
                  >
                    Storyset
                  </Link>
                </Typography>
              </Stack>
            </Grid>
            {/* Social */}
            <Grid item xs={12} sm={2}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{
                  justifyContent: 'flex-end',
                  [theme.breakpoints.down('md')]: { justifyContent: 'center' }
                }}
              >
                <Grid item>
                  <FooterLink
                    href="https://www.linkedin.com/company/diingu"
                    target="_blank"
                    underline="hover"
                  >
                    <img height="40px" src={LogoLinkedIn} alt="Logo linkedIn" />
                  </FooterLink>
                </Grid>
                <Grid item>
                  <FooterLink
                    href="https://www.instagram.com/diingu.de/"
                    target="_blank"
                    underline="hover"
                  >
                    <img
                      height="40px"
                      src={LogoInstagram}
                      alt="Logo Instagram"
                    />
                  </FooterLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </FooterWrapper>
    </>
  );
};

export default FooterPage;
