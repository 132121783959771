import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
  styled,
  useMediaQuery
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { KeyedObject } from 'types';

import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';

import PlaylistAddCircleRoundedIcon from '@mui/icons-material/PlaylistAddCircleRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

import imgLucas from 'assets/images/landing/team/lucas-square.png';
import { useEffect, useRef } from 'react';

interface SalesCardProps extends KeyedObject {
  icon: JSX.Element;
  title: string | JSX.Element;
  text: JSX.Element;
}

interface SalesStackProps {
  direction: 'row' | 'column';
}

export const SalesStack = ({ direction }: SalesStackProps) => {
  const theme = useTheme();

  return (
    <Stack
      direction={direction}
      width="100%"
      alignItems="center"
      justifyContent="space-evenly"
      spacing={2}
    >
      <Button
        variant="outlined"
        startIcon={
          <PlaylistAddCircleRoundedIcon
            sx={{ width: '1.6rem', height: '1.6rem' }}
          />
        }
        target="_blank"
        href="https://be2e51f7.sibforms.com/serve/MUIEAK6d0-Erb0CdL2oW6ItSH_oL3YtykWkI6GlsSB-0EU3ndk-M6gmUSvVoAPY8OjknsX9YQersX0QVyaIgElYVqD8f-YseEmYofCX59YVb_pw4j7EOf5JY-EnIn1HHvdEZhFxmtRpSw5_R8bDpLZTowXy9MB75MlV3-PxGfItN8Goh4QSF0xkiJOPAPHJgesMg9wse7HkTCAF0"
        size="large"
        sx={{
          textTransform: 'none',
          backgroundColor: 'white',
          boxShadow: theme.shadows[4],
          width: 220,
          height: 56,
          fontSize: '1.2rem',
          justifyContent: 'space-between'
        }}
      >
        Kontaktformular
      </Button>
      <Button
        variant="outlined"
        startIcon={
          <LocalPhoneRoundedIcon sx={{ width: '1.6rem', height: '1.6rem' }} />
        }
        href="tel:+49 152 595 576 93"
        size="large"
        sx={{
          textTransform: 'none',
          backgroundColor: 'white',
          boxShadow: theme.shadows[4],
          width: 220,
          height: 56,
          fontSize: '1.2rem',
          justifyContent: 'space-between'
        }}
      >
        0152 595 576 93
      </Button>
      <Button
        variant="outlined"
        size="large"
        startIcon={
          <EmailRoundedIcon sx={{ width: '1.6rem', height: '1.6rem' }} />
        }
        href="mailto:sales@diingu.de?subject=Demo-Anfrage%20für%20die%20Plattform&body=Sehr%20geehrtes%20Diingu%20Sales%20Team%2C%0D%0A%0D%0AIch%20interessiere%20mich%20für%20Ihre%20Plattform%20und%20würde%20mich%20sehr%20über%20eine%20Demo%20freuen.%20Bitte%20lassen%20Sie%20mich%20wissen%2C%20wann%20wir%20einen%20Termin%20dafür%20vereinbaren%20können.%0D%0A%0D%0AVielen%20Dank%20im%20Voraus.%0D%0A%0D%0AMit%20freundlichen%20Grüßen%2C%0D%0A%5BIhr%20Name%5D%0D%0A%5BKontaktdaten/Trägerdaten%5D"
        sx={{
          textTransform: 'none',
          backgroundColor: 'white',
          boxShadow: theme.shadows[4],
          width: 220,
          height: 56,
          fontSize: '1.2rem',
          justifyContent: 'space-between'
        }}
      >
        sales@diingu.de
      </Button>
    </Stack>
  );
};

const SalesCard = ({ icon, title, text }: SalesCardProps) => (
  <Stack alignItems="center" direction="column" textAlign="center" spacing={2}>
    <Box sx={{ mb: { xs: -2, md: 0 }, mt: 2 }}>{icon}</Box>
    <Typography variant="h2">{title}</Typography>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Typography maxWidth="30ch">{text}</Typography>
    </Box>
  </Stack>
);

const SalesImage = styled('img')({
  width: 120,
  maxWidth: '100%',
  borderRadius: 120 / 2
});

interface SalesProps extends KeyedObject {
  setShowFab: (arg0: boolean) => void;
}

const Sales = ({ setShowFab }: SalesProps) => {
  const theme = useTheme();
  const headerRef = useRef(null);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const current = headerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowFab(false);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [setShowFab]);

  const commonSX = {
    fontSize: '72px',
    color: theme.palette.primary.main
  };

  return (
    <Container id="salesContainer">
      <Grid
        container
        spacing={gridSpacing}
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12} md={9}>
          <Typography
            variant="h1"
            color="primary"
            component="div"
            textAlign="center"
            gutterBottom
            sx={{ mt: 4, mb: 2 }}
          >
            Testen Sie jetzt unsere Plattform kostenlos und unverbindlich aus!
          </Typography>
        </Grid>
        <Grid item md={4}>
          <SalesCard
            title={
              <>
                Erste <br />
                Kontaktaufnahme
              </>
            }
            text={
              <>
                Sie erreichen uns schnell und einfach via Telefon, Mail oder
                über das Kontaktformular.
              </>
            }
            icon={<LooksOneRoundedIcon sx={{ ...commonSX }} />}
          />
        </Grid>
        <Grid item md={4}>
          <SalesCard
            title={
              <>
                1 Stunde <br /> Online-Meeting
              </>
            }
            text={
              <>
                Wir stellen unsere Plattform genau vor und ermitteln gemeinsam
                die Vorteile für ihren Träger.
              </>
            }
            icon={<LooksTwoRoundedIcon sx={{ ...commonSX }} />}
          />
        </Grid>
        <Grid item md={4}>
          <SalesCard
            title={
              <>
                4 Wochen <br />
                kostenlos testen
              </>
            }
            text={
              <>
                Testen Sie unsere gesamte Plattform für 4 Wochen unverbindlich
                und kostenfrei.
              </>
            }
            icon={<Looks3RoundedIcon sx={{ ...commonSX }} />}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{ mt: 4 }} ref={headerRef}>
          <Stack
            direction={isLargeScreen ? 'row' : 'column'}
            width="100%"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <Box width="320px" display="flex" alignItems="center">
              <Typography
                variant="h3"
                color="primary"
                textAlign={isLargeScreen ? 'start' : 'center'}
              >
                Ab sofort Ihr Ansprechpartner:
              </Typography>
            </Box>
            <SalesImage src={imgLucas} alt="Profilbild Lucas Iske" />
            <Box
              width="320px"
              display="flex"
              alignItems="center"
              color="primary"
            >
              <Typography
                variant="h3"
                color="primary"
                textAlign={isLargeScreen ? 'end' : 'center'}
              >
                Lucas Iske Kundenbetreuung
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <SalesStack direction={isLargeScreen ? 'row' : 'column'} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sales;
